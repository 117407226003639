const COMMON = 'common';
const ERRORS = 'errors';
const TOASTS = 'toasts';
const TASK = 'task';
const ACCOUNT = 'account';
const USERS = 'users';
const GROUPS = 'groups';
const ASSETS = 'assets';
const PARTS = 'parts';
const PRODUCTS = 'products';
const MANUALS = 'manuals';
const DASHBOARD = 'dashboard';
const SCHEDULES = 'schedules';
const REQUESTS = 'requests';
const CHATS = 'chats';
const CHECK_LISTS = 'check-lists';
const LOGIN = 'login';
const CURRENCIES = 'currencies';
const IMAGE_EDITOR = 'image-editor';

// base namespaces
const BASE_NS = [COMMON, ERRORS, TOASTS, IMAGE_EDITOR, CURRENCIES];

export default BASE_NS;
export {
  COMMON,
  ERRORS,
  TOASTS,
  TASK,
  ACCOUNT,
  USERS,
  GROUPS,
  ASSETS,
  PARTS,
  PRODUCTS,
  MANUALS,
  DASHBOARD,
  SCHEDULES,
  REQUESTS,
  CHATS,
  CHECK_LISTS,
  LOGIN,
  CURRENCIES,
  IMAGE_EDITOR,
};
