import { useTranslation as translate } from 'react-i18next';
import BASE_NS from './constants';

export default function useTranslation(namespace?: string | string[]) {
  const [COMMON, ERRORS, TOASTS] = BASE_NS;

  const namespaces = [COMMON];
  if (namespace) {
    if (Array.isArray(namespace)) namespaces.push(...namespace);
    else namespaces.push(namespace);
  }

  const { t, i18n } = translate(namespaces);

  const t_errors = (key: string, config?: object) => {
    return t(key, { ns: ERRORS, ...config });
  };

  const t_toasts = (key: string, config?: object) => {
    return t(key, { ns: TOASTS, ...config });
  };

  const t_ns = (key: string, config?: object) => {
    return namespace ? t(key, { ns: namespace, ...config }) : '';
  };

  return { t, t_errors, t_toasts, t_ns, i18n };
}
